/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.Checkout {
    margin-block: 0;
    padding-block: 24px 104px;
    min-height: auto;

    @include mobile {
        padding-block: 0 80px;
    }

    &::before {
        @include desktop {
            display: none;
        }
    }

    &-SuccessPage {
        text-align: center;
        padding: 30px 16px;
    }

    &-StickyButtonWrapper {
        @include mobile {
            border-block-start: none;
            position: relative;
            inset-inline-start: 0;
            inset-block-end: 0;
            padding: 0;

            .hideOnScroll & {
                transform: none;
            }
        }
    }

    .CheckoutOrderSummary-ButtonWrapper {
        @include mobile {
            display: inline-block;
        }
    }

    &-ProgressSection {
        @include mobile {
            display: block;
        }
    }

    &-Header {
        padding-block-start: 16px;
        padding-block-end: 8px;
    }

    &-Title {
        font-size: 20px;
        line-height: 24px;

        @include desktop {
            font-size: 24px;
            line-height: 32px;
        }
    }

    &-PageTitle {
        margin-block-start: 0px;

        @include desktop {
            margin-block-start: 0;
        }
    }

    &-Heading {
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        margin-block-end: 16px;
    }

    &-Wrapper {
        @include desktop {
            padding-inline: 52px;
            padding-inline-end: 52px;
            grid-template-columns: 46.5% 48%;
            column-gap: 5.5%;
        }

        &_isSuccess {
            display: block;
            text-align: center;
            width: 100%;

            @include desktop {
                padding-block-start: 80px;
            }

            .Checkout {
                &-Header {
                    padding-block-start: 0;
                    justify-content: center;
                }

                &-Title {
                    font-size: 24px;
                    line-height: 32px;
                    margin-block-end: 2px;

                    @include desktop {
                        font-size: 32px;
                        line-height: 36px;
                    }
                }
            }
        }
    }

    &-StepBarTotal {
        background-color: var(--background-stroke-color);
        height: 6px;
    }

    &-StepsBorder {
        font-size: 20px;
        color: var(--body-content-secondary-color);
        font-weight: 700;

        @include desktop {
            font-size: 24px;
        }
    }

    &-StepBarActive {
        height: 6px;
        inset-block-start: -6px;

        &_isSecond {
            inset-inline-start: 0;
            width: 100%;
        }
    }

    &-TotalSteps {
        font-size: 20px;
        color: var(--body-content-secondary-color);
        font-weight: 700;

        @include desktop {
            font-size: 24px;
        }
    }

    &-SelectedStep {
        font-size: 20px;
        color: var(--body-content-secondary-color);
        font-weight: 700;

        @include desktop {
            font-size: 24px;
        }
    }

    .Breadcrumbs {
        .Breadcrumb {
            margin-block-end: 0;
        }
    }

    .CheckoutBilling {
        &-TermsAndConditions {
            margin-block-start: 12px;
        }

        &-Button {
            max-width: 200px;
            width: 100%;
            margin-block-start: 20px;

            @include mobile {
                max-width: inherit;
            }
        }
    }
}

.Popup.CheckoutTermsAndConditionsPopup {
    li::before {
        content: none;
    }

    li:last-child {
        margin-block-end: 8px;
    }

    li {
        list-style-type: unset;
        list-style-position: inside;
        margin-inline-start: 32px;
        margin-block-end: 8px;
    }
}
