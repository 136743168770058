/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.CheckoutSuccess {
    p {
        font-size: 16px;
        line-height: 24px;
        margin-block-end: 0;

        @include desktop {
            font-size: 18px;
        }
    }

    strong {
        @include desktop {
            /* stylelint-disable-next-line declaration-no-important */
            font-weight: 700 !important;
        }
    }

    &-Line {
        margin-block-start: 16px;
        /* stylelint-disable-next-line declaration-no-important */
        margin-block-end: 8px !important;
    }

    &-ButtonWrapper {
        @include mobile {
            position: relative;
            padding-block: 24px 0;
            padding-inline: 0;
            inset-block-end: 0;
            border-block-start: none;
        }

        .Button {
            width: 252px;
        }
    }
}
