/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.CheckoutPayment {
    border-block-start: none;
    padding-block: 8px;

    &-Button {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }

    .Field {
        &_type {
            &_radio {
                border-width: 6px;
                margin-block-start: 0;
                pointer-events: none;
            }
        }
    }

    &:hover {
        .Field {
            [type="radio"] {
                + .input-control {
                    @include desktop {
                        border-color: var(--primary-hover-color);
                    }
                }
            }
        }
    }
}
