/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.CheckoutAddressBook {
    margin-block-start: 8px;
    margin-block-end: 24px;

    @include desktop {
        margin-block-end: 32px;
    }

    .FieldForm-Fields {
        > .Field-Wrapper {
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
                grid-column: span 2;
            }

            &:last-child:nth-child(odd) {
                grid-column: unset;
            }
        }
    }

    .Field-LabelContainer {
        label {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
        }
    }
}
