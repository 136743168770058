/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.CheckoutOrderSummary {
    padding: 16px;
    background: var(--background-hover-color);
    margin-block-start: 16px;

    @include desktop {
        padding: 32px;
        margin-block-start: 0px;
    }

    &-Header {
        margin-block-end: 24px;

        @include mobile {
            display: block;
        }

        h2 {
            font-size: 20px;

            @include desktop {
                font-size: 24px;
            }
        }
    }

    &-Text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--body-content-secondary-color);

        @include desktop {
            font-size: 18px;
        }
    }

    &-Price {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--body-content-secondary-color);

        @include desktop {
            font-size: 18px;
        }
    }

    &-OrderItems {
        background: transparent;
    }

    .Draggable {
        background: transparent;
    }

    &-ButtonWrapper {
        @include mobile {
            --footer-totals-height: 0;

            display: block;
            height: 0;
            position: relative;
            width: 100%;
            inset-inline-start: 0;
            padding-inline: 0;
            padding-block-end: 8px;
            inset-block-end: 0;
            background-color: transparent;
            border-block-start: none;
            z-index: 80;

            .hideOnScroll & {
                transform: none;
            }
        }
    }

    &-SummaryItem {
        padding: 0 0 8px;

        &_isTotal {
            border-block-start: none;
            padding: 0;

            & p {
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: var(--body-content-color);

                @include desktop {
                    font-size: 18px;
                }
            }

            & div {
                font-weight: 700;
                color: var(--body-content-color);
            }
        }
    }

    .SwipeToDelete {
        &-RightSideContentWrapper {
            display: none;
        }
    }

    .CartItem {
        border-block-end: none;
        margin-block-end: 24px;

        &-Wrapper {
            background: transparent;
            padding: 0;

            &_isMobileLayout {
                grid-template-columns: 100%;
                grid-gap: 16px;
            }

            &_isSummary {
                grid-template-columns: 193px auto;
            }
        }

        &-CartItemRows {
            flex-direction: row;
            align-items: flex-start;

            @include desktop {
                flex-direction: column;
                align-items: unset;
            }
        }

        &-Delete {
            &_isMobileLayout {
                display: none;
            }
        }

        &-Picture {
            width: 193px;
            height: 180px;

            @include desktop {
                height: 121px;
            }

            img {
                object-fit: cover;
                object-position: center;
            }

            &_isMobileLayout {
                width: 100%;
                height: 180px;
            }
        }

        &-Heading {
            white-space: inherit;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;

            @include desktop {
                font-size: 18px;
            }
        }
    }
}
